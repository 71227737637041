import { API_BASE_URL } from './http';

const api = {
  api001: API_BASE_URL + '/CompanyNews',
  api002: API_BASE_URL + '/CompanyNewsbody',
  api003: API_BASE_URL + '/CompanyColumn',
  api004: API_BASE_URL + '/CompanyContact',
  api005: API_BASE_URL + '/Certificate',
  api006: API_BASE_URL + '/Advertisement',
  api007: API_BASE_URL + '/MessageSave',
  api008: API_BASE_URL + '/Partners',
  api009: API_BASE_URL + '/ProductCenter',
  api010: API_BASE_URL + '/EndCertificate',
  api011: API_BASE_URL + '/EndCertificateBody'
}
export default api;
