import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

const header_bar_scroll={
   zIndex: 1000,
   position:'fixed',
   top:0,
   width:'100%',
   left:0,
   marginLeft:0,
   paddingBottom:'10px'
 }
 const header_bar={
   zIndex: 1000,
 }
class HeaderBar extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
        show_moblie_menu: false,
        isOpen:null,
        navBg:false,
      }
    }
    //window的handleScroll滑动监听事件
   componentDidMount() {
      window.addEventListener('scroll', this.changeNavBg);
   }
   //进行scroll事件的注销
   componentWillUnmount() {
      window.removeEventListener('scroll', this.changeNavBg);
   }
   changeNavBg=(event)=>{
      if(window.scrollY > 0){
        this.setState({navBg:true})
      }
      else {
        this.setState({navBg:false})
      }
    }
   showMenu = () =>{
      console.log(1);
      this.setState({
         show_moblie_menu: true,
      })
   }
   hiddenMenu = () =>{
      this.setState({
         show_moblie_menu: false,
      })
   }
   openMenu = (str) =>{
      console.log(str);
      this.setState({
         isOpen:str
      })
   }
   render() {
    return (
      <div>
      {/* <!-- main menu start --> */}
      <div className={"talim-main-menu hidden-xs hidden-sm one_page transprent-menu heading_style_3 witr_h_h5"} style={this.state.navBg?header_bar_scroll:header_bar}>
         <div className={["talim_nav_area trp_nav_area scroll_fixed postfix",this.state.navBg?"prefix":null].join(' ')}>
            <div className={"container"}>
               <div className={"row logo-left"}>
                  <div className={"col-md-3 col-sm-3 col-xs-4"}>
                     <div className={"logo"}>
                        <Link className={"main_sticky_main_l"} to={"/"} title="yunjin">
                           <img src={require('../../assets/picture/logo1.png')} alt="yunjin"/>
                        </Link>
                        <Link className={"main_sticky_l"} to={"/"} title="yunjin">
                           <img src={require('../../assets/picture/logo2.png')} alt="yunjin"/>
                        </Link>
                     </div>
                  </div>
                  {/* <!-- MAIN MENU --> */}
                  <div className={"col-md-9 col-sm-9 col-xs-8"}>
                     <div className={"tx_mmenu_together"}>
                        <nav className={"talim_menu"}>
                           <ul className={"sub-menu"}>
                              <li className={"menu-item-has-children"}>
                                 <Link to={"/"}>首页</Link>
                              </li>
                              <li className={"menu-item-has-children"}>
                                 <Link to={"/about"}>关于我们</Link>
                              </li>
                              <li className={"menu-item-has-children"}>
                                 <Link to={"/news"}>信息中心</Link>
                              </li>
                              <li className={"menu-item-has-children"}>
                                 <Link to={"/product"}>产品中心</Link>
                              </li>
                              <li className="menu-item-has-children">
                                 <a href={"https://www.zj-yjsc.com"} target='_blank'>云锦商城</a>
                              </li>
                              <li className="menu-item-has-children">
                                 <Link to={"/contact"}>联系我们</Link>
                              </li>
                           </ul>
                        </nav>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   
      {/* <!-- mobile menu --> */}
      <div className={"mobile_logo_area"} id="mobile_logo_area">
         <div className={"container-fluid"}>
            <div className={"row"}>
               <div className={"col-12"}>
                  <div className={"mobilemenu_con"}>
                     <div className={"mobile_menu_logo text-center"}>
                        <Link to={""} title="yunjin">
                           <img src={require('../../assets/picture/logo1.png')} alt="yunjin"/>
                        </Link>
                     </div>
                     <div className={"mobile_menu_option"}>
                        <div className={"mobile_menu_o mobile_opicon"}>
                           <i className={"icofont-navigation-menu openclass"} onClick={()=>this.showMenu()}></i>
                        </div> 
                        <div className={["mobile_menu_inner mobile_p ",this.state.show_moblie_menu?"tx-s-open":null].join(' ')}>
                           <div className={"mobile_menu_content"}>
                              <div className="mobile_menu_logo text-center">
                                 <Link to={"/"} title="yunjin">
                                    <img src={require('../../assets/picture/logo1.png')} alt="yunjin"/>
                                 </Link>
                              </div>
                              <div className="menu_area mobile-menu mean-container">
                                 <div className='mean-bar'>
                                 <Link to="#nav" className="meanmenu-reveal" style={{right: '0px', left: 'auto', display: 'inline'}}><span></span><span></span><span></span></Link>
                                 <nav className="mean-nav">
                                    <ul className="sub-menu">
                                       <li className="menu-item-has-children">
                                          <Link to={"/"} onClick={()=>this.hiddenMenu()}>首页</Link>
                                       </li>
                                       <li className="menu-item-has-children">
                                          <Link to={"/about"} onClick={()=>this.hiddenMenu()}>关于我们</Link>
                                       </li>
                                       <li className="menu-item-has-children">
                                          <Link to={"/news"} onClick={()=>this.hiddenMenu()}>信息中心</Link>
                                       </li>
                                       <li className="menu-item-has-children">
                                          <Link to={"/product"} onClick={()=>this.hiddenMenu()}>产品中心</Link>
                                       </li>
                                       <li className="menu-item-has-children">
                                          <a href={"https://www.zj-yjsc.com"} target='_blank' onClick={()=>this.hiddenMenu()}>云锦商城</a>
                                       </li>
                                       <li className="menu-item-has-children">
                                          <Link to={"/contact"} onClick={()=>this.hiddenMenu()}>联系我们</Link>
                                       </li>
                                    </ul>
                                 </nav>
                                 </div>
                              </div>
                              <div className="mobile_menu_o mobile_cicon">
                                 <i className="icofont-close closeclass" onClick={()=>this.hiddenMenu()}></i>
                              </div>
                           </div>
                        </div>
                        <div className="mobile_overlay"></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      </div>
    );
  }
}

export default withNavigation(HeaderBar);