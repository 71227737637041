import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./footerBar.css";
import api from "../../api";


const FooterBar = () => {
  const [endCertificate, setEndCertificate] = useState(null);

  useEffect(()=>{
    getFooterBarList()
  },[])
  
  const getFooterBarList = async () =>{
    await fetch(api.api010,{
      method:'get',
      mode:'cors',
      headers:{
        'Content-Type': 'application/json', // 设置请求头
        'Accept': 'application/json'
      }
    })
    .then(res => res.json())
    .then(json => {
      if(json.code === "0000"){
        setEndCertificate(json.data);
      }
    })
  }
  
  const renderFooterBar = () =>{
    const pages=[]
    if(endCertificate){
      for(let i = 0; i < endCertificate.length; i++){
        pages.push(
          <li><Link to={"/show/image/"+endCertificate[i].Id}>{endCertificate[i].EndCertificateName}</Link></li>
        )
      }
    }
    return pages;
  }
  return(
    <div>
      {/* <!-- footer area start --> */}
      <div className="witrfm_area">
          {/* <!-- FOOTER MIDDLE AREA --> */}
          <div className="footer-middle">
            <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-3 px-10">
                      <div className="widget widget_twr_description_widget">
                        <div className="talim-description-area">
                            <Link to={"/"}>
                              <img src={require('../../assets/picture/logo2.png')} alt="image"/>
                            </Link>
                            <div className="social-icons">
                              <p>zjyjxx@163.com</p>
                              <p>浙江省温州市平阳县鳌江镇下河村新城B栋16号店面</p>
                              <p>联系电话 400-872-0577</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3 px-10">
                      <div className="widget widget_nav_menu">
                        <h2 className="widget-title">公司介绍</h2>
                        <div className="menu-our-service-container">
                            <ul className="menu">
                              <li><Link to={"/"}>首页</Link></li>
                              <li><Link to={"/about"}>关于我们</Link></li>
                              <li><Link to={"/product"}>产品中心</Link></li>
                              <li><Link to={"/news"}>信息中心</Link></li>
                              <li><Link to={"/contact"}>联系我们</Link></li>
                            </ul>
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3 px-10">
                      <div className="widget widget_nav_menu">
                        <h2 className="widget-title">资质证书</h2>
                        <div className="menu-our-service-container">
                            <ul className="menu">
                              {renderFooterBar()}
                            </ul>
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3 px-10">
                      <div className="widget_text widget widget_custom_html">
                        <h2 className="widget-title">营业时间</h2>
                        <div className="textwidget custom-html-widget">
                            <div className="witr_table">
                              <div className="witr_sub_table">
                                  <span>周一 至 周六</span>
                                  <span>08:30 -17:30</span>
                              </div>
                              <div className="witr_sub_table">		
                                  <span>联系电话:</span>
                                  <span>400-872-0577</span>
                              </div>

                              <Link to={"/"}>
                              <img src={require('../../assets/picture/wei.jpg')} alt="image" width={120}/>&nbsp;&nbsp;
                              <img src={require('../../assets/picture/wei2.jpg')} alt="image" width={120}/>
                            </Link>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>
          {/* <!-- END FOOTER MIDDLE AREA --> */}
          {/* <!-- FOOTER BOTTOM AREA --> */}
          <div className="footer-bottom">
            <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                      <div className="copy-right-text" >
                        <p>Copyright &copy; 2024.浙江云锦信息网络有限公司版权所有.<a href="https://beian.miit.gov.cn" target="_blank" color="#fff">浙ICP备2020039224号-3</a></p>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                      <div className="footer-menu">
                        <ul className="text-right">
                            <li><Link to={"/"}>主页</Link></li>
                            <li><Link to={"/about"}>关于我们</Link></li>
                            <li><Link to={"/news"}>新闻中心</Link></li>
                        </ul>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      {/* <!-- footer area end --> */}
    </div>
  )
}
export default FooterBar;
