import React from 'react'
import {Navigate, useRoutes} from 'react-router-dom'
import LoadableComponent from '../LoadComponent/LoadComponent'

const Home = LoadableComponent(()=>import('../../pages/Home/home'))  //参数一定要是函数，否则不会懒加载，只会代码拆分
const Detail = LoadableComponent(()=>import('../../pages/News/detail'))
const News = LoadableComponent(()=>import('../../pages/News/news'))
const About = LoadableComponent(()=>import('../../pages/About/about'))
const PDetail = LoadableComponent(()=>import('../../pages/Product/detail'))
const Product = LoadableComponent(()=>import('../../pages/Product/product'))
const Error404 = LoadableComponent(()=>import('../../pages/404'))
const ContactUs = LoadableComponent(()=>import('../../pages/ContactUs/contact'))
const ShowImg = LoadableComponent(()=>import('../../pages/ShowImg/showImg'))

const ContentMain = () =>
  useRoutes([
    // 其他所有未匹配到的 全部转移到 home
    { path: '*', element: <Navigate to="/404" /> },
    { path: '/', element: <Home /> },
    { path: '/about', element: <About /> },
    { path: '/product',
      children: [
        { index: true, element: <Product /> },
        { path: 'detail/:id', element: <PDetail /> },
      ],
    },
    { path: '/contact',element:<ContactUs />},
    {
      path: '/news',
      children: [
        { index: true, element: <News /> },
        { path: 'detail/:id', element: <Detail /> },
      ],
    },
    {
      path: '/show/image/:id', element: <ShowImg />
    },
    { path: '/404', element: <Error404 />},
  ])

export default ContentMain;
