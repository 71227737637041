import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './assets/css/bootstrap.min.css'
import './assets/css/venobox.css'
import './assets/css/plugin_theme_css.css'
import './assets/css/style.css'
import './assets/css/responsive.css'

import { BrowserRouter } from 'react-router-dom'
// import { HashRouter } from 'react-router-dom'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
