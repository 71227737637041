import React from 'react';
import HeaderBar from "./components/HeaderBar/headerBar";
import FooterBar from "./components/Footer/footerBar";
import ContainMain from "./components/Content/ContainMain";


class App extends React.Component{
  render() {
    return(
      <div>
          <HeaderBar />
          <ContainMain />
          <FooterBar />
      </div>
    )
  }
}

export default App
